<template>
  <v-container fluid>
    <v-data-table
      :items="turner"
      :headers="[
        { text: 'Turnerin', value: 'name', sortable: false },
        { text: 'Startnummer', value: 'order', sortable: false, align: 'center' },
        { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
        { text: 'Entfernen', value: 'del', sortable: false, align: 'center' },
        { text: 'startberechtigt', value: 'startrecht', sortable: false, align: 'center' }
      ]"
      :items-per-page="-1"
    >
      <template #item.orders="{item}">
        <base-edit-dialog
          :value="item.order"
          :id="item._id"
          label="Reihenfolge"
          @input="({ value, id: iid }) => updateorder(iid, value)"
        />
      </template>
      <template #item.add="{item}">
        <v-btn
          v-if="!item.active && numactive < 10 && item.startrecht.allowed"
          fab
          x-small
          @click="add(item._id)"
        >
          <v-icon>far fa-plus</v-icon>
        </v-btn>
        <v-btn
          v-else-if="!item.active && numactive < 10"
          fab
          x-small
          @click="addconfirm(item._id)"
        >
          <v-icon style="color:red;">far fa-plus</v-icon>
        </v-btn>
      </template>
      <template #item.del="{item}">
        <v-btn
          v-if="item.active && !item.used"
          fab
          x-small
          @click="del(item._id)"
        >
          <v-icon>far fa-minus</v-icon>
        </v-btn>
      </template>
      <template #item.startrecht="{item}">
        <template v-if="item.startrecht.allowed">
          <v-icon>far fa-check</v-icon>
        </template>
        <v-tooltip
          v-else
          bottom
        >
          <template v-slot:activator="{on}">
            <v-btn v-on="on" small fab text><v-icon style="color:red;">far fa-info-circle</v-icon></v-btn>
          </template>
          <span>
            <template v-if="!item.startrecht.dtbid">Fehler: keine DTB-ID!</template>
            <template v-else-if="!item.startrecht.club">
              DTB-ID: <v-icon>far fa-check</v-icon><br>
              Fehler: keine gültige Startmarke für Mannschaft!
            </template>
            <template v-else-if="!item.startrecht.team">
              DTB-ID: <v-icon>far fa-check</v-icon><br>
              Startmarke für Mannschaft: <v-icon>far fa-check</v-icon><br>
              Fehler: ist auch für höhere Mannschaft gemeldet!
            </template>
            <template v-else-if="!item.startrecht.banned">
              DTB-ID: <v-icon>far fa-check</v-icon><br>
              Startmarke für Mannschaft: <v-icon>far fa-check</v-icon><br>
              Fehler: Hat eine Turnportal-Sperre!
            </template>
            <template v-else-if="!item.startrecht.prevhigher">
              DTB-ID: <v-icon>far fa-check</v-icon><br>
              Startmarke für Mannschaft: <v-icon>far fa-check</v-icon><br>
              Hat keine Turnportal-Sperre: <v-icon>far fa-check</v-icon><br>
              Turnt an einem vorherigen Wettkampftag bereits in höherer Liga!<br><br>{{ item.startrecht.additional }}
            </template>
            <template v-else-if="!item.startrecht.dtl">
              DTB-ID: <v-icon>far fa-check</v-icon><br>
              Startmarke für Mannschaft: <v-icon>far fa-check</v-icon><br>
              Hat keine Turnportal-Sperre: <v-icon>far fa-check</v-icon><br>
              Turnt in früherem Wettkampftag nicht in höherer Liga: <v-icon>far fa-check</v-icon><br>
              Fehler: Hat keine Freigabe aufgrund DTL-Einsätzen! (Ggf. auf Mannschaftsübersicht "0" eingeben!)<br><br>{{ item.startrecht.additional }}
            </template>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <h3>Hinweise:</h3>
    <ul>
      <li>Turnerinnen, die schon an mindestens 1 Gerät aufgestellt wurden, können nicht mehr entfernt werden.</li>
      <li>Um diese zu entfernen, müssen diese zunächst von allen Geräten abgemeldet werden.</li>
      <li>Die Startnummer kann nicht manuell angepasst werden! Es werden Nummern fortlaufend vergeben.</li>
      <li>Sollte etwas am Startrecht nicht passen, so lässt sich über das <v-icon>far fa-info-circle</v-icon>-Symbol der Grund dafür anzeigen.</li>
    </ul>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'start',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    Liga: {}
  }),

  computed: {
    wkteam () {
      return this.e?.teams?.find(t => t.team._id === this.team)
    },
    turner () {
      return this.Liga?.teams?.find(t => t._team === this.team)?.athletes?.map(a => ({
        _id: a.person._id,
        startrecht: a.startrecht || {},
        name: a.person.familyName + ', ' + a.person.givenName,
        active: !!this.wkteam?.athletes?.find(t => t.person._id === a.person._id),
        order: this.wkteam?.athletes?.find(t => t.person._id === a.person._id)?.order,
        used: !!this.wkteam?.athletes?.find(t => t.person._id === a.person._id)?.results?.length
      })).sort((a, b) => a.name < b.name ? -1 : 1)
    },
    numactive () {
      return this.turner.filter(d => d.active).length
    },
    maxactive () {
      return this.turner.filter(d => d.active).reduce((acc, curr) => Math.max(acc, curr.order || 0), 0)
    }
  },

  methods: {
    add (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $order: Int!) {
          StbW2023WkTeamAthleteAdd(id: $id, team: $team, person: $person, order: $order) { _id }
        }`,
        variables: {
          id: this.id,
          team: this.team,
          person: id,
          order: this.maxactive + 1
        }
      })
    },
    async addconfirm (id) {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich aufstellen?', 'Diese Turnerin ist nach den Berechnungen des Systems nicht startberechtigt. Sollten Sie der Meinung sein, dass dies ein Fehler ist, so können Sie die Turnerin dennoch aufstellen und turnen lassen. Der Vorfall wird dann geprüft. Sollte sich herausstellen, dass die Turnerin nicht startberechtigt war, so wird der Wettkampf ggf. als verloren gewertet!', { width: 400 })) {
        this.add(id)
      }
    },
    del (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!) {
          StbW2023WkTeamAthleteDelete(id: $id, team: $team, person: $person) { _id }
        }`,
        variables: {
          id: this.id,
          team: this.team,
          person: id
        }
      })
    },
    updateorder (id, value) {
      /* this.mutate({
        mutation: gql`
          mutation($id: UUID!, $discipline: UUID!, $order: Int!) {
            StbM2021WkDisciplineUpdateOrder(id: $id, discipline: $discipline, order: $order) { _id }
          }
        `,
        variables: {
          id: this.id,
          discipline: id,
          order: parseInt(value)
        }
      }) */
    }
  },

  apollo: {
    Liga: {
      query: gql`query($id: UUID!, $event: UUID!) {
        Event(id: $id) {
          _id
          ... on StbW2023League {
            teams {
              _team
              athletes {
                person { _id givenName familyName }
                startrecht(id: $event) { dtbid club team banned prevhigher dtl allowed additional }
              }
            }
          }
        }
      }`,
      variables () {
        return {
          id: this.e._parent,
          event: this.e._id
        }
      },
      skip () {
        return !this.e?._parent
      },
      update: data => data.Event
    }
  }
}
</script>

<style scoped>

</style>
